.categories .categories_container .categories_add_form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4235294118);
}
.categories .categories_container .categories_add_form .cat_form_add {
  min-width: 500px;
  max-width: 80vw;
  background-color: #fff;
  padding: 1.5rem 10px;
  border-radius: 5px;
  position: relative;
}
.categories .categories_container .categories_add_form .cat_form_add .close_category_modal {
  position: absolute;
  top: 10px;
  right: 10px;
}
.categories .categories_container .categories_add_form .cat_form_add .cat_modal_title {
  margin-bottom: 10px;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group {
  margin-bottom: 1rem;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .category_input,
.categories .categories_container .categories_add_form .cat_form_add .form_group .category_subcategories {
  outline: none;
  border: 1px solid #000;
  padding: 10px;
  width: calc(100% - 20px);
  margin-top: 5px;
  border-radius: 5px;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .slides_carousel {
  display: flex;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .slides_carousel .slide {
  flex: 0 0 auto;
  display: none;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .slides_carousel .slide .subcategories_list_item {
  padding: 5px;
  background-color: #2118a0;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .slides_carousel .slide .subcategories_list_item .close_sub_cat {
  fill: #fff;
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .slides_carousel .active_slider {
  display: block;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .form_group_swiper .subcategories_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .form_group_swiper .subcategories_list .subcategories_list_item {
  padding: 5px;
  background-color: #2118a0;
  color: #fff;
  border-radius: 5px;
}
.categories .categories_container .categories_add_form .cat_form_add .form_group .form_group_swiper .subcategories_list .subcategories_list_item .close_sub_cat {
  fill: #fff;
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.categories .categories_container .categories_add_form .cat_form_add .cat_slider_controls {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.categories .categories_container .categories_add_form .cat_form_add .cat_slider_controls .slider_category_control {
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #1d0f94;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}
.categories .categories_container .categories_add_form .cat_form_add .btn_cat_add_wrapper {
  display: flex;
  justify-content: flex-end;
}
.categories .categories_container .categories_add_form .cat_form_add .btn_cat_add_wrapper .btn_cat_add {
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #1d0f94;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}
.categories .categories_container .categories_wrapper {
  padding-top: 10px;
}
.categories .categories_container .categories_wrapper .categories_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.categories .categories_container .categories_wrapper .categories_top .categories_tab_title {
  margin-bottom: 10px;
}
.categories .categories_container .categories_wrapper .categories_top .category_add {
  border-radius: 24px;
  padding: 10px 20px;
  background-color: #2ac6ff;
  border: 1px solid #2ac6ff;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #ffffff;
  align-items: center;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
}
.categories .categories_container .categories_wrapper .categories_top .category_add:hover {
  color: #ebebeb;
  background-color: #3bcbff;
  transition: 0.3s;
}

thead tr {
  cursor: unset !important;
}

table,
thead,
tbody,
tr {
  border: 1px solid #000;
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  border-right: 1px solid #000;
}

table {
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
}
table thead tr,
table tbody tr {
  background-color: #fff;
  cursor: pointer;
}
table thead tr:hover,
table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.4117647059);
}
table thead tr td,
table tbody tr td {
  padding: 10px;
  text-align: left;
}
table thead tr td span,
table tbody tr td span {
  padding: 3px 8px;
  background-color: #090080;
  width: -moz-min-content !important;
  width: min-content !important;
  color: #fff;
  font-family: Apercu, Roboto;
  border-radius: 5px;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 1.4rem !important;
  font-size: 13px;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  cursor: pointer;
}
table thead tr td span .remove_category,
table tbody tr td span .remove_category {
  fill: #fff;
  height: 10px;
  width: 10px;
  margin-left: 5px;
  margin-bottom: -1px;
  cursor: pointer;
}
table thead tr td:last-child,
table tbody tr td:last-child {
  text-align: center;
  width: -moz-max-content;
  width: max-content;
}
table thead tr td:last-child:hover,
table tbody tr td:last-child:hover {
  background-color: var(--modal-bg) !important;
}
table thead tr td:last-child .actions-wrapp,
table tbody tr td:last-child .actions-wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: row;
}
table thead tr td:last-child .actions-wrapp .report-action,
table tbody tr td:last-child .actions-wrapp .report-action {
  height: 20px;
  width: 20px;
  fill: #2ac6ff;
  margin-right: 10px;
}

.sub_cat_flexxer {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.sub_cat_flexxer .parent_sub_category {
  outline: none;
  border: 1px solid #000;
  padding: 10px;
  width: calc(100% - 20px);
  margin-top: 5px;
  border-radius: 5px;
}
.sub_cat_flexxer .parent_sub_category_btn {
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #1d0f94;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  margin-bottom: -3px;
}/*# sourceMappingURL=categories.css.map */