@use 'main/fonts.scss';
@use 'main/variables.scss';

* {
  padding: 0;
  margin: 0;
  border-style: border-box;
  -webkit-font-smoothing: inherit;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

html,
body {
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  font-family: Volte Rounded, Apercu, Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  direction: ltr;
  transition: background 2s;
}
.app {
  background-color: var(--background-color);
  transition: all 0.3s ease-in-out 0s;
}

/* Remove increment and decrement buttons from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background: linear-gradient(
    180deg,
    var(--background-color-one) 0%,
    var(--background-color-two) 20%,
    var(--background-color-three) 100%
  ) !important;
  transition: all 0.3s ease-in-out 0s;
  color: fieldtext !important;
}

//loading animation
/**
 * ==============================================
 * dot-flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 auto;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }
  50%,
  100% {
    background-color: #fff3f333;
  }
}

.loading {
  .ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: #00a0ffdb;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 10px #00a0ffdb;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  .ring:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #00a0ffdb;
    border-right: 3px solid #00a0ffdb;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
  }
  span {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animate 2s linear infinite;
  }
  span:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #00a0ffdb;
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px #00a0ffdb;
  }
  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
}

// .swiper {
//   width: 100%;
//   height: 100%;
// }
.swiper-slide {
  width: 100% !important;
}
.swiper-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}

.mobi-view {
  position: relative;
  height: 100vh;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
