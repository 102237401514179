.auth {
  height: 100vh;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.auth::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.auth .authContainer {
  margin: 0 5%;
  height: 100%;
}
.auth .authContainer .authWrapper {
  position: relative;
  height: 100%;
}
.auth .authContainer .authWrapper .authForm {
  width: 350px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
}
.auth .authContainer .authWrapper .authForm .authformheader {
  display: flex;
  align-items: center;
  margin: 20px 5px;
  padding-bottom: 20px;
  border-bottom: 1px solid #919191;
}
.auth .authContainer .authWrapper .authForm .authformheader .headerLogoLink img {
  height: 30px;
  width: 40px;
  margin-right: 20px;
}
.auth .authContainer .authWrapper .authForm .authformheader h2 {
  font-size: 1.2rem;
  font-weight: 800;
}
.auth .authContainer .authWrapper .authForm .authentificationform {
  margin-top: 20px;
}
.auth .authContainer .authWrapper .authForm .authentificationform .authformgroup {
  position: relative;
  margin: 30px 0;
  margin-bottom: 20px;
}
.auth .authContainer .authWrapper .authForm .authentificationform .authformgroup .authinput {
  outline: none;
  border: 1px solid rgb(211, 211, 211);
  padding: 10px 13px;
  box-shadow: none !important;
  border-radius: 5px;
  background-color: rgb(255, 252, 215);
  display: unset !important;
  width: calc(100% - 26px);
}
.auth .authContainer .authWrapper .authForm .authentificationform .passresetlink {
  color: #0077ff;
  cursor: pointer;
}
.auth .authContainer .authWrapper .authForm .authentificationform .authsubBtn {
  outline: none;
  border: none;
  background-color: #000000;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 5px 0;
  padding: 10px 20px;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  margin: 20px 0;
}
.auth .authContainer .authWrapper .register {
  z-index: 1;
}
.auth .authContainer .authWrapper .login {
  z-index: 2;
  border-radius: 5px;
  padding: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Adjust the blur amount as desired */
  background-color: rgba(255, 255, 255, 0.3);
}/*# sourceMappingURL=auth.css.map */