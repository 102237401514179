.categories {
  .categories_container {
    .categories_add_form {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0000006c;
      .cat_form_add {
        min-width: 500px;
        max-width: 80vw;
        background-color: #fff;
        padding: 1.5rem 10px;
        border-radius: 5px;
        position: relative;
        .close_category_modal {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .cat_modal_title {
          margin-bottom: 10px;
        }
        .form_group {
          margin-bottom: 1rem;
          .category_input,
          .category_subcategories {
            outline: none;
            border: 1px solid #000;
            padding: 10px;
            width: calc(100% - 20px);
            margin-top: 5px;
            border-radius: 5px;
          }
          .slides_carousel {
            display: flex;
            .slide {
              flex: 0 0 auto;
              display: none;
              .subcategories_list_item {
                padding: 5px;
                background-color: #2118a0;
                color: #fff;
                border-radius: 5px;
                margin-right: 10px;
                .close_sub_cat {
                  fill: #fff;
                  height: 10px;
                  width: 10px;
                  cursor: pointer;
                }
              }
            }
            .active_slider {
              display: block;
            }
          }
          .form_group_swiper {
            .subcategories_list {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 5px;
              .subcategories_list_item {
                padding: 5px;
                background-color: #2118a0;
                color: #fff;
                border-radius: 5px;
                .close_sub_cat {
                  fill: #fff;
                  height: 10px;
                  width: 10px;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .cat_slider_controls {
          display: flex;
          justify-content: space-between;
          margin: 20px 0;
          .slider_category_control {
            outline: none;
            border: none;
            padding: 10px 30px;
            border-radius: 5px;
            background-color: #1d0f94;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            color: #fff;
          }
        }
        .btn_cat_add_wrapper {
          display: flex;
          justify-content: flex-end;
          .btn_cat_add {
            outline: none;
            border: none;
            padding: 10px 30px;
            border-radius: 5px;
            background-color: #1d0f94;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            color: #fff;
          }
        }
      }
    }
    .categories_wrapper {
      padding-top: 10px;
      .categories_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .categories_tab_title {
          margin-bottom: 10px;
        }
        .category_add {
          border-radius: 24px;
          padding: 10px 20px;
          background-color: #2ac6ff;
          border: 1px solid #2ac6ff;
          text-align: center;
          line-height: 19px;
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1.6px;
          text-transform: uppercase;
          color: #ffffff;
          align-items: center;
          transition: color 0.15s ease-in-out 0s;
          display: inline-block;
          &:hover {
            color: #ebebeb;
            background-color: #3bcbff;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

thead {
  tr {
    cursor: unset !important;
  }
}
table,
thead,
tbody,
tr {
  border: 1px solid #000;
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  border-right: 1px solid #000;
}
table {
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
  thead,
  tbody {
    tr {
      background-color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #ffffff69;
      }
      td {
        padding: 10px;
        text-align: left;
        span {
          padding: 3px 8px;
          background-color: #090080;
          width: min-content !important;
          color: #fff;
          font-family: Apercu, Roboto;
          border-radius: 5px;
          white-space: nowrap;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-height: 1.4rem !important;
          font-size: 13px;
          max-height: 6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: break-word;
          cursor: pointer;
          .remove_category {
            fill: #fff;
            height: 10px;
            width: 10px;
            margin-left: 5px;
            margin-bottom: -1px;
            cursor: pointer;
          }
        }
        &:last-child {
          text-align: center;
          width: max-content;
          &:hover {
            background-color: var(--modal-bg) !important;
          }
          .actions-wrapp {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-evenly;
            flex-direction: row;
            .report-action {
              height: 20px;
              width: 20px;
              fill: #2ac6ff;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.sub_cat_flexxer {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  .parent_sub_category {
    outline: none;
    border: 1px solid #000;
    padding: 10px;
    width: calc(100% - 20px);
    margin-top: 5px;
    border-radius: 5px;
  }
  .parent_sub_category_btn {
    outline: none;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: #1d0f94;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    margin-bottom: -3px;
  }
}
