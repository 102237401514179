.newproduct {
  padding: 1rem;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup {
  margin-bottom: 1rem;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup label {
  margin-bottom: 10px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup input,
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup textarea,
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup select {
  padding: 10px;
  outline: none;
  border: 1px solid #000;
  border-radius: 5px;
  width: calc(100% - 20px);
  margin-top: 5px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup textarea {
  min-height: 100px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup select {
  width: unset !important;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row {
  display: flex;
  margin-bottom: 22px;
  justify-content: space-between;
  margin: 15px 0px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row {
  width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row {
    width: 100%;
  }
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px dashed var(--text-color);
  border-radius: 5px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content {
  display: flex;
  align-items: center;
  position: relative;
  width: 80%;
}
@media (max-width: 480px) {
  .newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content {
    width: 100%;
  }
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content .icon {
  margin-right: 15px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content .icon img {
  max-width: 100%;
  height: auto;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content .content-label {
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 22px;
  color: var(--text-primary);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 800px;
  overflow-x: scroll;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content .content-label::-webkit-scrollbar {
  scrollbar-color: rgb(35, 42, 46);
  width: 5px;
  max-height: 3px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0);
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list {
  padding: 8px 15px;
  border-radius: 23px;
  font-size: 0.9rem;
  text-decoration: none;
  color: #eaeaea;
  background-color: #505050;
  margin-right: 3px;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list:hover {
  background-color: #797979;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .row-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .row-wrap {
    display: none;
  }
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .project_brief .row-wrap .button {
  background: #2ac6ff;
  border-radius: 4px;
  padding: 9px 23px 11px;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
  z-index: unset !important;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .input-field-row .input-file-row .file-field {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .newprod_thumbnail {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .newprod_thumbnail .thumb_nprod_image {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #000;
  cursor: pointer;
  margin-right: 10px;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprod-formGroup .newprod_thumbnail .active_prod_thumbnail {
  border: 3px solid #157eff;
}
.newproduct .newproduct-container .newproductwrapper .newproduct-form .newprodsub-btn {
  padding: 10px 20px;
  border-radius: 23px;
  border: none;
  outline: none;
  background: linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #3584e4);
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
}/*# sourceMappingURL=newproduct.css.map */